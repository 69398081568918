@use '../visuals/scale/color';
@use '../visuals/scale/size';

.btn {
  --pb: #{size.$size-12};
  --pt: #{size.$size-12};

  font-size: size.$size-16;

  &:hover {
    --bg: var(--grey-1);
    --border: 0.2rem solid var(--grey-1);

    color: color.$w;
  }

  > .btn-icon {
    margin-right: 1em;
  }
}

.btn-case-study,
.btn-details {
  > .btn-icon {
    transform: translateY(-1px);
  }
}

.btn-gh,
.btn-view-live {
  > .btn-icon {
    transform: translateY(-2px);
  }
}

.btn-npm {
  > .btn-icon {
    transform: translateY(2px);
    width: 16px;

    > .npm_svg__bg {
      fill: var(--c-primary);
    }

    > .npm_svg__n {
      fill: var(--c-secondary);
    }
  }

  &:hover {
    > .btn-icon {
      > .npm_svg__bg {
        fill: color.$w;
      }

      > .npm_svg__n {
        fill: var(--grey-1);
      }
    }
  }
}
