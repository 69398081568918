$k: #000;
$grey-a: #555;
$grey-b: #777;
$grey-c: #999;
$grey-d: #aaa;
$grey-e: #f2f2f2;
$w: #fff;

:root {
  --c-primary: #{$k};
  --c-secondary: #{$w};
  --grey-1: #{$grey-a};
  --grey-2: #{$grey-b};
  --grey-3: #{$grey-c};
  --grey-4: #{$grey-d};
}

body.dark {
  --c-primary: #{$w};
  --c-secondary: #{$k};
  --grey-1: #{$grey-c};
  --grey-3: #{$grey-a};
}
