@use '../../../visuals/scale/bp';
@use '../../../visuals/scale/size';

.grid-blog-cs-sot > .subgrid-content {
  > .early-work > .sticky-media {
    margin-top: size.$size-54;
  }

  > .subgrid-ss {
    grid-template-rows: repeat(3, size.$size-157) repeat(2, auto);

    > .ss-sm {
      grid-row: span 3;
    }
  }
}

@media (max-width: bp.$md) {
  .grid-blog-cs-sot > .subgrid-content > .subgrid-ss {
    grid-template-rows: repeat(3, size.$size-147) repeat(2, auto);
  }
}

@media (max-width: size.$size-800) {
  .grid-blog-cs-sot > .subgrid-content > .subgrid-ss {
    > .ss-sm > .tablet {
      height: auto;
      width: 100%;
    }
  }
}

@media (max-width: 640px) {
  .grid-blog-cs-sot > .subgrid-content > .subgrid-ss {
    > .ss-sm > .phone-vertical {
      height: auto;
      width: 100%;
    }
  }
}

@media (max-width: bp.$sm) {
  .grid-blog-cs-sot > .subgrid-content > .subgrid-ss {
    grid-template-rows: repeat(4, auto);

    > .ss {
      &.ss-sm {
        grid-row: auto;
      }

      &.ss-lg {
        grid-row: auto;
      }
    }
  }
}
