@use '../../visuals/scale/bp';
@use '../../visuals/scale/color';
@use '../../visuals/scale/size';

.main-footer {
  grid-template-rows: repeat(2, size.$size-157);
  margin-top: size.$size-422;

  > nav {
    grid-row-start: 1;

    > ul {
      list-style: none;

      > li {
        margin-bottom: 0.5em;
      }
    }
  }

  > p {
    align-self: end;
    grid-column: 1/4;
    grid-row-start: 1;
    margin-bottom: 0;

    > button {
      cursor: pointer;
      text-decoration: underline;

      &:hover {
        color: var(--c-primary);
      }
    }
  }

  .personal-title {
    grid-column: 1/3;
    grid-row: 1;

    > dd {
      line-height: 1.68em;
    }
  }

  .internal-nav {
    grid-column: -2/-3;
  }

  .external-nav {
    grid-column: -1/-2;

    > #external-nav-title {
      bottom: -600px;
      left: 0;
      position: fixed;
    }
  }
}

@media (max-width: bp.$md) {
  .main-footer {
    margin-top: size.$size-422;

    &.grid {
      grid-template-rows: repeat(2, size.$size-147);
    }

    > p {
      align-self: auto;
      grid-row: 2;
    }
  }
}

@media (max-width: size.$size-760) {
  .main-footer {
    &.grid {
      column-gap: size.$size-18;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, auto) size.$size-147;
      row-gap: size.$size-32;
    }

    > nav {
      grid-row-start: 2;
    }

    > p {
      grid-row-start: 3;
    }

    .personal-title {
      grid-column: 1/-1;
      grid-row: auto;

      > dd {
        margin-bottom: 0;
      }
    }

    .external-nav,
    .internal-nav {
      grid-column: auto;
    }
  }
}

@media (max-width: bp.$sm) {
  .main-footer {
    margin-top: size.$size-200;

    &.grid {
      grid-template-rows: repeat(2, auto) size.$size-176;
    }
  }
}
