@use '../../visuals/grid';
@use '../../visuals/scale/bp';
@use '../../visuals/scale/size';
@use '../../visuals/scale/typography';

.grid-landing {
  grid-template-rows:
    repeat(2, auto) repeat(5, size.$size-157) auto repeat(4, size.$size-157)
    auto;
  margin-bottom: size.$size-27;
  margin-top: size.$size-211;

  > h1 {
    align-self: start;
    grid-column: 1/-1;
    justify-self: start;
    transform: translate(-3px, -15px);

    > .title-br {
      display: none;
    }
  }

  > p {
    grid-column: 1/5;
    transform: translateY(-10px);
  }

  > a.cta:first-of-type {
    align-self: start;
    grid-column: 1/4;
    justify-self: start;
    transform: translateY(-15px);
  }

  > .featured-header {
    align-self: end;
    grid-column: 1/-1;
    justify-self: start;

    &-projects {
      grid-row: 4/5;
    }

    &-case-studies {
      grid-row: 9/10;
    }
  }

  > .subgrid-featured {
    grid-column: 1/-1;
    grid-template-rows: repeat(3, size.$size-157);

    &.featured-projects {
      grid-row: 5/8;
    }

    &.featured-case-studies {
      grid-row: 10/13;
    }

    > .subgrid-featured-item {
      grid-column: span 3;
      grid-row: 1/-1;
      grid-template-rows: repeat(3, size.$size-157);

      &:hover > .subgrid-featured-item-content > figcaption > h3 {
        text-decoration: underline;
      }

      > .subgrid-featured-item-content {
        grid-column: 1/-1;
        grid-row: 1/-1;
        grid-template-rows: repeat(3, size.$size-157);

        > .blind-frame:first-child {
          grid-column: 1/-1;
          grid-row: 1/3;

          > img {
            object-fit: cover;

            height: 100%;
            width: 100%;
          }
        }

        > figcaption {
          grid-column: 1/-1;
          grid-row: 3/4;
        }
      }
    }
  }

  > .view-more {
    align-self: end;
    grid-column: 1/-1;
    justify-self: center;

    &-projects {
      grid-row: 8/9;
    }

    &-case-studies {
      grid-row: 13/14;
    }
  }
}

@media (max-width: size.$size-1150) {
  .grid-landing > .subgrid-featured {
    columns: 2;
    display: block !important;
  }
}

@media (max-width: size.$size-980) {
  .grid-landing > p {
    grid-column: 1/5;
  }
}

@media (max-width: bp.$md) {
  .grid-landing {
    grid-template-rows:
      repeat(2, auto) repeat(5, size.$size-147) auto repeat(4, size.$size-147)
      auto;
    margin-top: size.$size-201;

    > h1 {
      transform: translate(-1px, -9px);
    }

    > p {
      transform: translateY(3px);
    }

    > a.cta:first-of-type {
      transform: translateY(-9px);
    }

    > .subgrid-featured {
      grid-template-rows: repeat(3, size.$size-147);

      > .subgrid-featured-item {
        grid-template-rows: repeat(3, size.$size-147);

        > .subgrid-featured-item-content {
          grid-template-rows: repeat(3, size.$size-147);
        }
      }
    }
  }
}

@media (max-width: size.$size-956) {
  .grid-landing > p {
    grid-column: 1/4;
  }
}

@media (max-width: size.$size-860) {
  .grid-landing > p {
    grid-column: 1/5;
  }
}

@media (max-width: size.$size-800) {
  .grid-landing > p {
    grid-column: 1/-1;
  }
}

@media (max-width: size.$size-780) {
  .grid-landing {
    grid-template-rows: repeat(2, auto) repeat(2, size.$size-147) auto auto size.$size-147 auto auto;

    > p,
    > a.cta:first-of-type {
      grid-column: 1/-1;
    }

    > .featured-header {
      &-case-studies {
        grid-row: 7/8;
      }
    }

    > .subgrid-featured {
      columns: auto;
      grid-row: 5/6;

      &.featured-projects {
        grid-row: 5/6;
      }

      &.featured-case-studies {
        grid-row: 8/9;
      }

      > .subgrid-featured-item {
        display: block !important;
        margin-bottom: size.$size-54;

        > .subgrid-featured-item-content {
          display: block !important;

          > figcaption {
            margin-top: 1.5em;
          }
        }
      }
    }

    > .view-more {
      align-self: end;
      justify-self: start;

      &-projects {
        grid-row: 6/7;
      }

      &-case-studies {
        grid-row: 9/10;
      }
    }
  }
}

@media (max-width: size.$size-600) {
  .grid-landing > .cta {
    align-self: center;
  }
}

@media (max-width: size.$size-520) {
  .grid-landing > p {
    margin-top: 0.5em;
    transform: none;
  }
}

@media (max-width: bp.$sm) {
  .grid-landing {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto) size.$size-176 auto auto size.$size-176 auto auto;
    margin-bottom: size.$size-24;
    margin-top: 0;

    > h1 > .title-br {
      display: inline;
    }

    > p {
      grid-row: 2/3;
      margin-top: 0;
      transform: translateY(-9px);
    }

    > a.cta:first-of-type {
      grid-row: 3/4;
    }

    > .featured-header {
      &-projects {
        grid-row: 3/4;
      }

      &-case-studies {
        grid-row: 6/7;
      }
    }

    > .subgrid-featured {
      &.featured-projects {
        grid-row: 4/5;
      }

      &.featured-case-studies {
        grid-row: 7/8;
      }

      > .subgrid-featured-item {
        margin-bottom: size.$size-24;
      }
    }

    > .view-more {
      &-projects {
        grid-row: 5/6;
      }

      &-case-studies {
        grid-row: 8/9;
      }
    }
  }
}
