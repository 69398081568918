@use '../visuals/scale/bp';
@use '../visuals/grid';
@use '../visuals/scale/size';

.grid-feed {
  grid-template-rows: size.$size-157 auto;
  margin-bottom: size.$size-27;
  margin-top: size.$size-211;

  > h1 {
    align-self: start;
    grid-column: 1/3;
    grid-row: 1/2;
    justify-self: start;
    transform: translateY(-17px);
  }

  > .content {
    list-style: none;

    grid-column: 3/-1;
    grid-row: 1/3;
    transform: translateY(-7px);

    > .item {
      margin-bottom: size.$size-81;

      &:last-child {
        margin-bottom: 0;
      }

      > a {
        text-decoration: none;

        > h2 {
          display: inline-block;
        }

        &:hover > h2 {
          text-decoration: underline;
        }

        > p {
          line-height: 1.25em;

          margin-bottom: 0;
          margin-top: size.$size-3;
        }

        > .badge-list > li {
          margin-top: size.$size-7;
        }
      }
    }
  }
}

@media (max-width: bp.$md) {
  .grid-feed {
    margin-top: size.$size-201;

    > h1 {
      margin-bottom: size.$size-30;
    }

    > .content {
      grid-column: 1/4;
      grid-row-start: 2;
    }
  }
}

@media (max-width: size.$size-760) {
  .grid-feed {
    > h1 {
      grid-column: 1/-1;
    }

    > .content {
      grid-column: 1/-1;
    }
  }
}

@media (max-width: bp.$sm) {
  .grid-feed {
    grid-template-columns: 1fr;
    margin-bottom: size.$size-24;
    margin-top: 0;

    > h1 {
      margin-bottom: size.$size-32;
      transform: translateY(-15px);
    }
  }
}
