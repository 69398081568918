@use '../../visuals/grid';
@use '../../visuals/scale/bp';
@use '../../visuals/scale/size';
@use '../../visuals/scale/typography';

.grid-about {
  grid-template-rows: repeat(2, size.$size-157) auto repeat(2, size.$size-157);
  margin-bottom: size.$size-27;
  margin-top: size.$size-211;

  > .cover {
    grid-column: 1/5;
    grid-row: 1/3;

    > h1 {
      display: inline-block;
      transform: translate(-1px, -17px);
    }

    > p {
      margin-top: 1em;
      transform: translateY(-22px);
    }
  }

  > .content {
    grid-column: 1/4;
    grid-row: 3/4;

    > p {
      > .alert {
        cursor: pointer;
      }

      > .blue-comic-sans:hover {
        color: blue;
        // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
        font-family: 'Comic Sans MS', 'Comic Sans', typography.$txt-font-family;
      }
    }
  }

  > .closing {
    grid-column: 1/4;
    grid-row: -2/-1;

    > p > .cta {
      margin-top: size.$size-36;
    }
  }

  > .subgrid-resume {
    grid-column: 5/7;
    grid-row: 3/-1;

    h2 {
      display: inline-block;
      margin-bottom: size.$size-14;
    }

    > .work,
    > .edu {
      > dl {
        margin-bottom: size.$size-42;
      }
    }

    > .subgrid-skills {
      column-gap: size.$size-27;
      columns: 2;
      grid-column: 1/-1;

      > section {
        break-inside: avoid;

        &.frontend {
          display: inline-block;
        }

        > ul {
          list-style: none;

          margin-bottom: size.$size-42;

          > li {
            margin-bottom: 0.5em;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1142px) {
  .grid-about {
    grid-template-rows: repeat(2, size.$size-157) auto repeat(3, size.$size-157) auto;

    > .cover {
      grid-column: 1/4;
    }

    > .closing {
      grid-row: 5/6;
    }

    > .subgrid-resume {
      @include grid.grid-base;

      grid-column: 1/5;
      grid-row: 7/8;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto auto;

      > .work {
        grid-column: 1/3;
      }

      > .edu {
        grid-column: 3/5;
      }

      > .subgrid-skills {
        columns: 4;
      }
    }
  }
}

@media (max-width: size.$size-980) {
  .grid-about > .content {
    margin-top: size.$size-54;
  }
}

@media (max-width: bp.$md) {
  .grid-about {
    grid-template-rows: repeat(2, size.$size-147) auto repeat(3, size.$size-147) auto;
    margin-top: size.$size-201;

    > .cover {
      grid-column: 1/4;
    }

    > .closing {
      grid-row: 5/6;
    }

    > .subgrid-resume {
      grid-row: 7/8;
    }
  }
}

@media (max-width: size.$size-780) {
  .grid-about {
    > .cover,
    > .closing,
    > .subgrid-resume {
      grid-column: 1/-1;
    }

    > .content {
      grid-column: 1/-1;
      margin-top: 0;
    }
  }
}

@media (max-width: size.$size-760) {
  .grid-about {
    display: block;

    > content,
    > .closing {
      margin-bottom: size.$size-174;
    }
  }
}

@media (max-width: size.$size-600) {
  .grid-about > .content {
    margin-top: size.$size-54;
  }
}

@media (max-width: size.$size-560) {
  .grid-about > .subgrid-resume {
    grid-template-columns: 1fr 1fr;

    > .work {
      grid-column: 1/2;
    }

    > .edu {
      grid-column: 2/3;
    }

    > .subgrid-skills {
      columns: 2;
    }
  }
}

@media (max-width: size.$size-520) {
  .grid-about > .content {
    margin-top: size.$size-81;
  }
}

@media (max-width: bp.$sm) {
  .grid-about {
    grid-template-rows: repeat(2, size.$size-176) auto repeat(2, size.$size-176) auto;
    margin-bottom: size.$size-24;
    margin-top: 0;

    > .cover {
      > h1 {
        margin-bottom: size.$size-24;
      }

      > p {
        margin-bottom: size.$size-147;
      }
    }

    > .content {
      grid-column: 1/-1;
      margin-bottom: 0;
      margin-top: 0;
    }

    > .closing {
      grid-column: 1/-1;
      grid-row: 4/5;
      margin-bottom: size.$size-200;

      > p > .cta {
        margin-top: size.$size-24;
      }
    }

    > .subgrid-resume {
      grid-column: 1/-1;
      grid-row: 6/7;

      > .subgrid-skills {
        column-gap: size.$size-24;
      }
    }
  }
}
