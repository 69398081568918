@use '../../../visuals/scale/bp';
@use '../../../visuals/scale/color';
@use '../../../visuals/scale/size';

.grid-blog-cs-lean-space > .subgrid-content {
  > .subgrid-colors {
    list-style: none;

    display: grid;
    grid-column: 1/-1;
    grid-gap: size.$size-27;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: size.$size-157;

    > li {
      background-color: #ccc;

      padding: 0.5em;

      &:nth-child(1) {
        background-color: #3dbbc2;
        color: color.$w;
      }

      &:nth-child(2) {
        background-color: #54e1e6;
        color: color.$k;
      }

      &:nth-child(3) {
        background-color: #eee;
        color: color.$k;
      }

      &:nth-child(4) {
        background-color: #babbbd;
        color: color.$k;
      }

      &:nth-child(5) {
        background-color: #333;
        color: color.$w;
      }

      &:nth-child(6) {
        background-color: #292c31;
        color: color.$w;
      }
    }
  }

  > .subgrid-ss {
    grid-template-rows: repeat(8, size.$size-157) auto;

    > .ss {
      background-color: #eee;

      &.ss-lg {
        grid-row: span 5;
      }

      &.ss-sm {
        grid-row: span 3;
      }
    }
  }
}

@media (max-width: bp.$md) {
  .grid-blog-cs-lean-space > .subgrid-content {
    > .subgrid-ss {
      grid-template-rows: repeat(8, size.$size-147) auto;
    }

    > .subgrid-colors {
      grid-template-rows: size.$size-147;
    }
  }
}

@media (max-width: 770px) {
  .grid-blog-cs-lean-space > .subgrid-content {
    > .subgrid-colors {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, size.$size-147);
    }
  }
}

@media (max-width: 680px) {
  .grid-blog-cs-lean-space > .subgrid-content > .subgrid-ss {
    > .ss-sm > .phone-vertical,
    > .ss-lg > .tablet {
      height: auto;
      width: 100%;
    }
  }
}

@media (max-width: bp.$sm) {
  .grid-blog-cs-lean-space > .subgrid-content {
    > .subgrid-colors {
      grid-gap: size.$size-24;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, size.$size-176);
    }

    > .subgrid-ss {
      grid-template-rows: repeat(4, auto);

      > .ss {
        &.ss-sm {
          grid-column: 1/-1;
          grid-row: auto;
        }

        &.ss-lg {
          grid-row: auto;
        }
      }
    }
  }
}
