@use '../../visuals/scale/bp';
@use '../../visuals/scale/size';
@use '../../visuals/scale/typography';

.grid-contact {
  grid-template-rows: repeat(3, size.$size-157);
  margin-bottom: size.$size-27;
  margin-top: size.$size-211;

  > .cover {
    grid-column: 1/5;
    grid-row: 1/2;

    > h1 {
      display: inline-block;
      margin-bottom: size.$size-30;
      transform: translate(-1px, -9px);
    }

    > p {
      float: left;
      transform: translateY(-9px);
    }
  }

  > ul {
    list-style: none;

    grid-column: 1/4;
    grid-row: 3/4;

    > li {
      margin-bottom: size.$size-27;

      > a {
        font: typography.$disp-medium size.$size-36 typography.$disp-font-family;
        text-decoration: none;

        align-items: center;
        display: flex;

        &:hover {
          text-decoration: underline;
        }

        > img {
          height: size.$size-30;
          margin-right: size.$size-36;
        }

        > svg {
          margin-right: size.$size-36;
          width: size.$size-30;
        }
      }
    }
  }
}

@media (max-width: bp.$md) {
  .grid-contact {
    margin-top: size.$size-201;
  }
}

@media (max-width: size.$size-760) {
  .grid-contact {
    > .cover,
    > ul {
      grid-column: 1/-1;
    }
  }
}

@media (max-width: bp.$sm) {
  .grid-contact {
    grid-template-columns: 1fr;
    margin-bottom: size.$size-24;
    margin-top: 0;

    > .cover {
      > h1 {
        margin-bottom: size.$size-27;
      }
    }

    > ul {
      > li {
        margin-bottom: size.$size-24;

        > a {
          font-size: size.$size-24;

          > img {
            height: size.$size-20;
            margin-right: size.$size-24;
          }

          > svg {
            margin-right: size.$size-24;
            width: size.$size-20;
          }
        }
      }
    }
  }
}
