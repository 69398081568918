$trans-duration: 1s;
$trans-timing-fn: ease-out;

body {
  transition-duration: $trans-duration;
  transition-property: background, background-color, color, fill, opacity;
  transition-timing-function: $trans-timing-fn;
}

a {
  transition: color $trans-duration $trans-timing-fn;
}

button {
  transition-duration: $trans-duration;
  transition-property: background, background-color, color, fill;
  transition-timing-function: $trans-timing-fn;
}

.main-nav > ul {
  transition: background-color $trans-duration $trans-timing-fn;
}

path,
polygon,
rect {
  transition-duration: $trans-duration;
  transition-property: fill, opacity;
  transition-timing-function: $trans-timing-fn;
}
