@use '../../../visuals/scale/bp';
@use '../../../visuals/scale/size';

.grid-blog-general {
  display: grid;
  grid-template-rows: auto;
  margin-bottom: size.$size-27;
  margin-top: size.$size-211;

  .cover {
    grid-column: 2/5;
    margin-bottom: size.$size-184;
    transform: translateY(-17px);

    > .subtitle {
      margin-bottom: size.$size-9;
    }

    > .time {
      margin-bottom: 0;
    }

    > .badge-list > li {
      margin-top: size.$size-12;
    }
  }

  .content {
    grid-column: 2/5;

    > p:last-child {
      margin-bottom: 0;
    }
  }
}

@media (max-width: bp.$md) {
  .grid-blog-general {
    margin-top: size.$size-201;
  }
}

@media (max-width: size.$size-780) {
  .grid-blog-general {
    .cover,
    .content {
      grid-column: 1/4;
    }
  }
}

@media (max-width: size.$size-590) {
  .grid-blog-general {
    .cover,
    .content {
      grid-column: 1/-1;
    }
  }
}

@media (max-width: bp.$sm) {
  .grid-blog-general {
    margin-bottom: size.$size-24;
    margin-top: 0;
    transform: translateY(-16px);

    > .cover {
      margin-bottom: size.$size-59;
    }
  }
}
