@use '../../../visuals/scale/bp';
@use '../../../visuals/scale/color';
@use '../../../visuals/scale/size';

.grid-cs {
  --row-gap-space: #{size.$size-184};

  grid-template-rows: auto auto;
  margin-bottom: size.$size-27;
  margin-top: size.$size-211;

  .ss-lg {
    grid-column: 1/-1;
  }

  .ss-sm {
    grid-column: span 3;
  }

  > .cover {
    grid-column: 1/-1;
    margin-bottom: var(--row-gap-space);
    transform: translateY(-8px);

    > h1 {
      > .title-prefix {
        letter-spacing: 0.1em;
        text-transform: uppercase;

        float: left;
        transform: translateX(1px);

        > span {
          opacity: 0;
        }
      }

      > .title {
        clear: left;
        float: left;
        transform: translate(-3px, -21px);
      }
    }

    > .subtitle {
      clear: left;
      float: left;
      margin-bottom: size.$size-9;
      transform: translateY(-26px);
    }

    > .time {
      clear: left;
      float: left;
      margin-bottom: 0;
      transform: translateY(-30px);
    }

    > .badge-list {
      clear: left;
      float: left;
      transform: translateY(-31px);

      > li {
        margin-top: size.$size-12;
      }
    }
  }

  > .subgrid-content {
    grid-column: 1/-1;
    grid-row: 2/3;
    transform: translateY(-17px);

    > .intro {
      grid-column: span 3;
    }

    > .sbs-header {
      transform: translateY(-16px);
    }

    p:last-child {
      margin-bottom: 0;
    }

    .left-half,
    .left-half-priority {
      grid-column: 1/4;
    }

    .right-half,
    .right-half-priority {
      grid-column: 4/-1;
    }

    .full {
      grid-column: 1/-1;
    }

    > h2,
    > h3 {
      display: inline-block;
      grid-column: 1/-1;
    }

    > .subgrid-subcontent {
      grid-column: 1/-1;

      > img {
        width: 100%;
      }

      > .sticky-media {
        align-self: start;
        justify-self: start;
        position: sticky;
        top: size.$size-27;
      }
    }

    > .multicol-txt {
      column-gap: size.$size-27;
      columns: 2;
      grid-column: 1/-1;
    }

    > .vid {
      grid-column: 1/-1;
    }

    > .subgrid-ss {
      display: grid;
      grid-gap: size.$size-27;
      grid-template-columns: repeat(6, 1fr);

      .mobile {
        background-color: color.$k;
        border-radius: 20px;

        &.phone-horizontal {
          padding: 10px 15px;
        }

        &.phone-vertical {
          height: 100%;
          padding: 15px 10px;
          width: auto;
        }

        &.tablet {
          height: 100%;
          padding: 30px 20px;
          width: auto;
        }
      }

      > div.ss-lg {
        padding: 30px;
      }

      > .ss {
        background-color: color.$grey-e;

        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;

        &.ss-sm {
          padding: 15px;
        }
      }

      > figcaption {
        font-size: size.$size-14;
        font-style: italic;

        grid-column: 1/-1;
        transform: translateY(-14px);
      }
    }

    > .btn-area {
      display: grid;
      grid-column: 1/-1;
      width: 100%;

      > .btn-double-group {
        display: grid;
        grid-column: span 2;
        grid-gap: size.$size-27;
        grid-template-columns: repeat(2, minmax(size.$size-159, 1fr));
        justify-self: center;

        > a {
          width: 100%;

          > .btn {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: size.$size-1150) {
  .grid-cs > .subgrid-content > .ss-sm {
    grid-column: 1/-1;
  }
}

@media (max-width: 1142px) {
  .grid-cs {
    > .subgrid-content {
      .left-half {
        grid-column: 1/3;
      }

      .right-half-priority {
        grid-column: 3/-1;
      }
    }
  }
}

@media (max-width: bp.$md) {
  .grid-cs {
    --row-gap-space: #{size.$size-174};

    margin-top: size.$size-201;

    > .cover {
      h1 > .title {
        transform: translateY(-7px);
      }

      > .subtitle {
        transform: translateY(-8px);
      }

      > .time {
        transform: translateY(-10px);
      }

      > .badge-list {
        transform: translateY(-11px);
      }
    }

    > .subgrid-content > .sbs-header {
      transform: translateY(-10px);
    }
  }
}

@media (max-width: size.$size-956) {
  .grid-cs {
    > .cover {
      transform: translateY(-5px);
    }

    > .subgrid-content {
      .left-half,
      .left-half-priority {
        grid-column: span 2;
      }

      .right-half,
      .right-half-priority {
        grid-column: span 2;
      }
    }
  }
}

@media (max-width: 770px) {
  .grid-cs > .subgrid-content {
    > .intro {
      grid-column: 1/-1;
    }

    .left-half,
    .left-half-priority,
    .right-half,
    .right-half-priority {
      grid-column: 1/-1;
    }

    > .multicol-txt {
      columns: auto;
    }

    > .sbs-header {
      transform: none;
    }
    > .subgrid-subcontent > .sticky-media {
      margin-top: size.$size-72;
    }
  }
}

@media (max-width: 584px) {
  .grid-cs > .subgrid-content > .btn-area > .btn-double-group {
    justify-self: auto;
  }
}

@media (max-width: bp.$sm) {
  .grid-cs {
    --row-gap-space: #{size.$size-200};

    margin-bottom: size.$size-24;
    margin-top: 0;

    > .subgrid-content {
      > .subgrid-subcontent > .sticky-media {
        margin-top: size.$size-64;
      }

      > .subgrid-ss {
        grid-gap: size.$size-24;
        grid-template-columns: minmax(100%, 1fr);

        > .ss-sm {
          grid-column: 1/-1;
        }
      }

      > .btn-area > .btn-double-group {
        grid-gap: size.$size-24;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
