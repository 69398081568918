@use '../../../visuals/scale/size';

.grid-blog-cs-ml2020 {
  .wide {
    grid-column: 2/6;
  }
}

@media (max-width: size.$size-956) {
  .grid-blog-cs-ml2020 {
    .wide {
      grid-column: 1/4;
    }
  }
}

@media (max-width: size.$size-780) {
  .grid-blog-cs-ml2020 {
    .wide {
      grid-column: 1/-1;
    }
  }
}

@media (max-width: size.$size-400) {
  .grid-blog-cs-ml2020 > .cover > h1 > .title.long-h {
    font-size: 54px !important;
  }
}
