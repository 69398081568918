@use './scale/bp';
@use './scale/color';
@use './scale/size';
@use './scale/typography';

html {
  font-size: size.$size-18;
}

body {
  background-color: var(--c-secondary);
  color: var(--c-primary);
  font: typography.$txt-roman size.$size-18 typography.$txt-font-family;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  margin-left: size.$size-27;
  margin-right: size.$size-27;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: typography.$disp-font-family;
  font-weight: typography.$disp-medium;

  &:focus {
    outline: 0;
  }
}

p {
  line-height: 1.5em;

  margin-bottom: 1.5em;
}

blockquote {
  background-color: color.$grey-e;
  color: #555;
  font-style: italic;

  margin: 0;
  padding: 1.5em;
}

code {
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
}

a,
a:hover,
a:visited {
  color: var(--c-primary);
}

b,
strong {
  font-weight: typography.$bold;
}

ol {
  counter-reset: ordered-list;
  line-height: 1.5em;
  list-style: none;

  padding-left: 3em;
}

ol > li::before {
  content: '0' counter(featured-posts-list);

  left: -3em;
  position: absolute;
}

ol > li {
  counter-increment: featured-posts-list;

  margin-bottom: 1em;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
}

dl {
  line-height: 1.5em;
}

dt {
  font-weight: typography.$txt-medium;
}

dd {
  line-height: 1.5em;

  margin-bottom: 1.5em;
}

.container {
  margin: 0 auto;
  max-width: size.$size-1161;
}

.ss > img,
.vid > video {
  width: 100%;
}

.ss,
.vid {
  > figcaption {
    font-size: size.$size-14;
    font-style: italic;

    margin-top: size.$size-14;
  }
}

noscript {
  text-align: center;

  display: block;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 9999;
}

@media (max-width: bp.$sm) {
  html {
    font-size: size.$size-16;
  }

  body {
    font-size: size.$size-16;

    margin-left: size.$size-18;
    margin-right: size.$size-18;
  }

  .ss,
  .vid {
    > figcaption {
      font-size: size.$size-12;

      margin-top: size.$size-12;
    }
  }
}
