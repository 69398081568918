@use '../../visuals/trans';

.blind-frame {
  display: block;
  position: relative;
}

.blind-frame > .blind {
  background-color: var(--c-secondary);

  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;

  transition: background-color trans.$trans-duration trans.$trans-timing-fn;
}
