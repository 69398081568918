@use './scale/color';
@use './scale/size';

.ats {
  --bg: rgba(0, 0, 0, 0.5);

  background: var(--bg);
  overflow: scroll;

  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 4;

  > .modal {
    max-width: 600px;
    z-index: 4;

    > .modal-content {
      > .modal-h {
        color: color.$k;
      }

      > p {
        color: color.$grey-a;

        > a {
          color: color.$grey-a;

          &:hover {
            color: color.$k;
          }
        }
      }
    }

    > .modal-close {
      --top: 2.5em;

      > svg {
        fill: color.$k;

        height: 21px;
      }
    }
  }
}

@media (max-width: size.$size-560) {
  .ats > .modal {
    left: 0;
    margin: 0;
    max-width: 100vh;
    min-height: 100vh;
    top: 0;
    transform: none !important;

    > .modal-close {
      --top: 2.4em;

      > svg {
        height: 14px;
      }
    }
  }
}
