@use '../../../visuals/scale/bp';
@use '../../../visuals/grid';
@use '../../../visuals/scale/size';
@use '../../../visuals/scale/typography';

.grid-project {
  margin-bottom: size.$size-27;
  margin-top: size.$size-211;

  .project-details-gap {
    margin-bottom: size.$size-81;
  }

  .btn {
    width: 100%;
  }

  > .project-overview {
    grid-column: span 3;
    transform: translateY(-17px);
  }

  > .ss-lg {
    grid-column: 1/-1;
  }

  > .ss-sm {
    grid-column: span 3;
  }

  > .vid {
    grid-column: 1/-1;

    > video {
      max-width: 960px;
    }
  }

  > h2,
  > h3 {
    align-self: start;
    justify-self: start;
  }

  > .subgrid-mid-desc {
    grid-column: 1/-1;
    margin-bottom: size.$size-81;
    margin-top: size.$size-81;

    > h3 {
      align-self: start;
      grid-column: span 3;
      justify-self: start;
    }

    > p {
      grid-column: span 3/-1;
      margin-bottom: 0;
    }

    > .cta {
      align-self: start;
      grid-column: span 3/-1;
      justify-self: start;
    }
  }

  > .subgrid-project-desc {
    grid-column: span 3;
    margin-bottom: size.$size-81;

    > section {
      grid-column: 1/-1;

      > p:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  > .project-details-header {
    font-size: size.$size-18;

    grid-column: 1/2;
  }

  > .subgrid-project-details {
    list-style-type: none;

    grid-column: 4/-1;
    grid-row: span 2;
    margin-bottom: size.$size-81;

    > section {
      grid-column: span 1;

      > section {
        margin-bottom: size.$size-54;

        > h4 {
          margin-bottom: 1em;
        }

        > ul {
          list-style-type: none;

          > li {
            margin-bottom: 0.5em;
          }
        }

        // stylelint-disable-next-line no-descending-specificity
        > p {
          line-height: normal;

          margin-bottom: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  > .cover {
    grid-column: 1/-1;
    margin-bottom: size.$size-100;
    transform: translateY(-27px);

    > h1 {
      float: left;
    }

    > h2 {
      clear: left;
      float: left;
    }

    > .badge-list {
      clear: left;
      float: left;

      > li {
        margin-top: size.$size-12;
      }
    }
  }

  > .btn-option-a {
    grid-column: 4/5;
  }

  > .btn-option-b {
    grid-column: 5/6;
  }

  > .btn-triple-group {
    &.btn-option-a {
      grid-column: 4/6;
    }

    &.btn-option-b {
      grid-column: 4/5;
    }

    &.btn-option-c {
      grid-column: 5/6;
    }
  }
}

@media (max-width: size.$size-1150) {
  .grid-project {
    > .subgrid-mid-desc > h3 {
      grid-column: span 2;
    }

    > .subgrid-project-desc {
      grid-column: 1 / span 3;
    }

    > .ss-sm {
      grid-column: 1/-1;
    }
  }
}

@media (max-width: bp.$md) {
  .grid-project {
    margin-top: size.$size-201;

    > .cover {
      transform: translateY(-16px);
    }

    > .project-overview {
      transform: none;
    }

    > .subgrid-mid-desc {
      > h3,
      > p,
      > .cta {
        grid-column: span 3;
      }
    }

    > .subgrid-project-details {
      grid-column: 3/-1;
    }

    > .btn-option-a {
      grid-column: 3/4;
    }

    > .btn-option-b {
      grid-column: 4/5;
    }

    > .btn-triple-group {
      &.btn-option-a {
        grid-column: -3/-1;
      }

      &.btn-option-b {
        grid-column: -3/-2;
      }

      &.btn-option-c {
        grid-column: -2/-1;
      }
    }
  }
}

@media (max-width: size.$size-780) {
  .grid-project {
    > .project-details-header {
      font-size: size.$size-24;
    }

    > .subgrid-mid-desc {
      margin-bottom: size.$size-72;
      margin-top: size.$size-72;
    }

    > .subgrid-project-details {
      grid-column: 1/-1;
    }

    > .btn-option-a,
    > .btn-option-b {
      grid-column: auto;
    }

    > .btn-triple-group {
      &.btn-option-a {
        grid-column: 1/3;
      }

      &.btn-option-b {
        grid-column: 1/2;
      }

      &.btn-option-c {
        grid-column: 2/3;
      }
    }
  }
}

@media (max-width: size.$size-600) {
  .grid-project {
    > .project-overview {
      grid-column: 1/-1;
    }

    > .subgrid-project-desc {
      grid-column: 1/-1;
    }
  }
}

@media (max-width: size.$size-590) {
  .grid-project {
    > .btn-triple-group {
      &.btn-option-a {
        grid-column: 1/3;
      }

      &.btn-option-b,
      &.btn-option-c {
        grid-row: 12/13;
      }
    }

    > .subgrid-project-desc {
      > .btn-triple-group {
        &.btn-option-a {
          grid-column: 1/3;
        }

        &.btn-option-b,
        &.btn-option-c {
          grid-row: 3/4;
        }
      }
    }
  }
}

@media (max-width: size.$size-560) {
  .grid-project {
    > .subgrid-project-desc {
      grid-column: 1/-1;
    }
  }
}

@media (max-width: bp.$sm) {
  .grid-project {
    margin-bottom: size.$size-24;
    margin-top: 0;

    .project-details-gap {
      margin-bottom: size.$size-72;
    }

    > .cover {
      margin-bottom: size.$size-59;
    }

    > .subgrid-project-desc {
      margin-bottom: size.$size-72;
    }

    > .subgrid-project-details {
      margin-bottom: size.$size-72;
    }
  }
}

@media (max-width: size.$size-390) {
  .grid-project {
    grid-template-columns: 1fr;

    > .btn-triple-group {
      &.btn-option- {
        &a,
        &b,
        &c {
          grid-column: auto;
          grid-row: auto;
        }
      }
    }

    > .subgrid-project-desc {
      grid-template-columns: 1fr;

      > .btn-triple-group {
        &.btn-option- {
          &a,
          &b,
          &c {
            grid-column: auto;
            grid-row: auto;
          }
        }
      }
    }

    > .subgrid-project-details {
      grid-template-columns: 1fr 1fr;
    }
  }
}
