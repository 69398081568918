@use "sass:map";
@use 'styles/util/appearance';
@use 'styles/util/gen';
@use 'styles/util/layout';
@use '../scale/bp';
@use './typography';

@media (max-width: map.get(bp.$bp, 'md')) {
  @include gen.gen-classes(appearance.$data, 'md\\\:');
  @include gen.gen-classes(layout.$data, 'md\\\:');
  @include gen.gen-classes(typography.$data, 'md\\\:');
}

@media (max-width: map.get(bp.$bp, 'sm')) {
  @include gen.gen-classes(appearance.$data, 'sm\\\:');
  @include gen.gen-classes(layout.$data, 'sm\\\:');
  @include gen.gen-classes(typography.$data, 'sm\\\:');
}
