@use '../../visuals/scale/bp';
@use '../../visuals/scale/size';
@use '../../visuals/scale/typography';

.sun-moon_svg {
  &__moon {
    opacity: 1;
  }

  &__sun {
    opacity: 0;
  }
}

.dark .sun-moon_svg {
  &__moon {
    opacity: 0;
  }

  &__sun {
    opacity: 1;
  }
}

.main-header {
  align-items: flex-end;
  display: flex;
  height: size.$size-157;
  justify-content: space-between;
  margin-bottom: size.$size-27;

  .btn-nav {
    background: none;
    border: 0;
    cursor: pointer;

    vertical-align: middle;

    > .menu-icon {
      height: 44px;
      transform: translateY(-3px);
    }

    > .sun-moon_svg {
      fill: var(--grey-1);

      width: 19.24px;

      > span {
        position: absolute;
      }
    }
  }

  > h2 > .logo > svg {
    height: 44px;
    transform: translateY(-1px);
  }

  // Phone Menu Button
  > .btn-nav {
    display: none;
  }

  > .main-nav {
    > #main-nav-title {
      left: 0;
      position: absolute;
      top: -600px;
    }

    > ul {
      list-style: none;

      transform: translateX(3px);

      > li {
        font-family: typography.$disp-font-family;

        display: inline-block;
        margin-left: size.$size-36;

        &.phone-home {
          display: none;
        }

        &.btn-lightness > .btn-nav {
          height: 26px;
          position: relative;
          width: 26px;

          > .sun-moon_svg {
            left: 50%;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
          }

          > span {
            left: 0;
            position: absolute;
            top: -600px;
          }
        }

        &.phone-menu-close {
          display: none;
        }

        > a {
          color: var(--grey-1);
          letter-spacing: 0.1em;
          text-decoration: none;

          &:active,
          &:hover,
          &.active {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@media (max-width: bp.$md) {
  .main-header {
    height: size.$size-147;
  }
}

@media (max-width: size.$size-560) {
  .main-header {
    .btn-nav {
      > .sun-moon_svg {
        fill: var(--c-primary);

        height: 14.35px;
      }
    }

    // Phone Menu Button
    > .btn-nav {
      display: inline-block;
    }

    > .main-nav {
      > ul {
        background-color: var(--c-secondary);

        bottom: 0;
        display: none;
        left: 0;
        padding: size.$size-18 size.$size-27;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 2;

        &.open {
          display: block;
        }

        > li {
          font-size: size.$size-59;
          font-weight: typography.$disp-medium;

          display: block;
          margin-bottom: size.$size-30;
          margin-left: 0;
          transform: translateY(-6px);

          &.phone-home {
            display: inline-block;
          }

          &.btn-lightness {
            bottom: size.$size-18;
            margin-bottom: 0;
            position: fixed;
            vertical-align: bottom;

            > .btn-nav {
              height: auto;
              position: static;
              width: auto;

              > .sun-moon_svg {
                left: auto;
                position: static;
                top: auto;
                transform: none;
              }

              > span {
                font-size: size.$size-20;

                margin-left: 1em;
                position: static;
              }
            }
          }

          &.phone-menu-close {
            display: inline-block;
            position: fixed;
            right: size.$size-27;
            top: size.$size-18;
          }

          > a {
            color: var(--c-primary);
            letter-spacing: normal;
          }
        }
      }
    }
  }
}

@media (max-width: bp.$sm) {
  .main-header {
    align-items: flex-start;
    height: size.$size-176;
    margin-bottom: size.$size-24;
    margin-top: size.$size-18;

    > h2 > .logo > svg {
      height: 18px;
    }

    > .main-nav > ul {
      padding: size.$size-18;
    }

    > .btn-nav > .menu-icon {
      height: 18px;
      transform: translateY(-1px);
    }
  }
}
