$disp-font-family: neue-haas-grotesk-display, 'Helvetica Neue', Helvetica, Arial,
  sans-serif;
$txt-font-family: neue-haas-grotesk-text, 'Helvetica Neue', Helvetica, Arial,
  sans-serif;

$disp-roman: 500;
$disp-medium: 600;
$txt-roman: 400;
$txt-medium: 500;
$bold: 700;
