@use './scale/bp';
@use './scale/size';

@mixin grid-base {
  display: grid !important;
  grid-gap: size.$size-27;
}

.grid {
  @include grid-base;

  grid-template-columns: repeat(auto-fill, minmax(size.$size-159, 1fr));
}

.multicol-ss {
  columns: 2;
  grid-column: 1/-1;
  grid-gap: size.$size-27;

  > img {
    width: 100%;
  }

  > figcaption {
    column-span: all;
  }
}

.subgrid-ss {
  grid-column: 1/-1;
}

@media (max-width: size.$size-520) {
  .multicol-ss {
    columns: auto;

    > .ss-sm:first-child {
      margin-bottom: size.$size-27;
    }
  }
}

@media (max-width: bp.$sm) {
  .grid {
    grid-gap: size.$size-24;
  }
}
