.grid-project-adam {
  > .vid {
    // TODO
    // background-color: #c5c5c5; // ff
    background-color: #cdcbcd; // chrome

    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 1em;

    > figcaption {
      color: #555;

      > a,
      > a:hover,
      > a:visited {
        color: #555;
      }
    }
  }
}
