$size-3: 3px;
$size-7: 7px;
$size-9: 9px;
$size-10: 10px;
$size-12: 12px;
$size-14: 14px;
$size-16: 16px;
$size-18: 18px;
$size-20: 20px;
$size-21: 21px;
$size-24: 24px;
$size-27: 27px;
$size-30: 30px;
$size-32: 32px;
$size-36: 36px;
$size-42: 42px;
$size-54: 54px;
$size-59: 59px;
$size-63: 63px;
$size-64: 64px;
$size-72: 72px;
$size-81: 81px;
$size-100: 100px;
$size-147: 147px;
$size-157: 157px;
$size-159: 159px;
$size-174: 174px;
$size-176: 176px;
$size-184: 184px;
$size-200: 200px;
$size-201: 201px;
$size-211: 211px;
$size-390: 390px;
$size-400: 400px;
$size-422: 422px;
$size-470: 470px;
$size-520: 520px;
$size-560: 560px;
$size-590: 590px;
$size-600: 600px;
$size-760: 760px;
$size-780: 780px;
$size-800: 800px;
$size-860: 860px;
$size-956: 956px;
$size-960: 960px;
$size-980: 980px;
$size-1140: 1140px;
$size-1150: 1150px;
$size-1161: 1161px;
